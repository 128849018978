/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'suit-diamond': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.384 1.226a.463.463 0 00-.768 0l-4.56 6.468a.54.54 0 000 .612l4.56 6.469a.463.463 0 00.768 0l4.56-6.469a.54.54 0 000-.612zM6.848.613a1.39 1.39 0 012.304 0l4.56 6.468a1.61 1.61 0 010 1.838l-4.56 6.468a1.39 1.39 0 01-2.304 0L2.288 8.92a1.61 1.61 0 010-1.838z"/>',
    },
});
